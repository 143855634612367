//品目種別
export const Item_Class: { [key: string]: any } = {
  "Parts": {
    value: '00',
    text: '部品'
  },
  "Steel": {
    value: '01',
    text: '鋼材'
  },
  "Base_Material": {
    value: '02',
    text: '母材'
  },
  "Top_Parts": {
    value: '03',
    text: '最上位部品'
  }
}
//品目種別
export const itemClassOptionsCommon = [
  {
    label: "最上位部品",
    value: "03",
  },
  {
    label: "部品",
    value: "00",
  },
  {
    label: "鋼材",
    value: "01",
  },
  {
    label: "母材",
    value: "02",
  },
]
//品目連関種別
export const ItemRelation_Class: { [key: string]: any } = {
  "Relation_Target_Item": {
    value: '0',
    text: '連関対象品目'
  },
  "Relation_Target_Outer_Item": {
    value: '1',
    text: '連関対象外品目'
  },
  "Spot_Steel": {
    value: '2',
    text: 'スポット鋼材'
  }
}
//品目連関種別
export const itemRelationClasssCommon = [
  {
    label: "連関対象品目",
    value: "0",
  },
  {
    label: "連関対象外品目",
    value: "1",
  },
  {
    label: "スポット鋼材",
    value: "2",
  }
]
//原単位連関表確定_ステータス
export const Basic_Unit_Linkage_Table_Confirm_Status: { [key: string]: any } = {
  "Approving": {
    value: '0',
    text: '承認中'
  },
  "Approval": {
    value: '1',
    text: '承認済'
  },
  "Confirm": {
    value: '2',
    text: '確定済'
  },
  "Confirm_Err": {
    value: '9',
    text: '確定エラー'
  }
}
export const confirmStatus = [
  {
    label: "0",
    value: "未承認",
  },
  {
    label: "1",
    value: "承認済",
  },
  {
    label: "2",
    value: "確定済",
  },
  {
    label: "9",
    value: "確定エラー",
  }
]
//原単位連関表承認処理_ステータス
export const Basic_Unit_Linkage_Table_Approval_Processing_Status: { [key: string]: any } = {
  "Not_Approval": {
    value: '0',
    text: '未承認'
  },
  "Approval": {
    value: '1',
    text: '承認済'
  }
}
//管理単位
export const Unit: { [key: string]: any } = {
  "PC": {
    value: 'pc',
    text: 'pc'
  },
  "KG": {
    value: 'kg',
    text: 'kg'
  }
}
//管理単位
export const unitCommon = [
  {
    label: "pc",
    value: "pc",
  },
  {
    label: "kg",
    value: "kg",
  }
]
//薄目引き
export const Usumebiki: { [key: string]: any } = {
  "None": {
    value: '0',
    text: '無し'
  },
  "Percent_One": {
    value: '3',
    text: '1%'
  },
  "Percent_Two": {
    value: '1',
    text: '2%'
  },
  "Percent_Three": {
    value: '2',
    text: '3%'
  },
}
//薄目引き
export const usumebikiOptionsCommon = [
  {
    label: "無し",
    value: "0",
  },
  {
    label: "1%",
    value: "3",
  },
  {
    label: "2%",
    value: "1",
  },
  {
    label: "3%",
    value: "2",
  }
]
//削除フラグ
export const Delete_Flag: { [key: string]: any } = {
  "Delete": {
    value: true,
    text: '削除済'
  },
  "Not_Delete": {
    value: false,
    text: '未削除'
  }
}

//最大桁数
export const maxlengthNum: { [key: string]: any } = {
  //8の最大桁数
  "lengthEight": 8,
  //9の最大桁数(整数部の桁数は最大値5、又は小数部の桁数は最大値3)
  "lengthNine": 9,
  "lengthFive": 5,
  "lengthThree": 3,
  //10の最大桁数
  "lengthTen": 10,
  //12の最大桁数
  "lengthTwelve": 12,
  //20の最大桁数
  "lengthTwenty": 20,
  //21の最大桁数
  "lengthTwentyOne": 21,
  //40の最大桁数
  "lengthForty": 40,
  //50の最大桁数
  "lengthFifty": 50,
  //80の最大桁数
  "lengthEighty": 80,
  //100の最大桁数
  "lengthHundred": 100,
  //256の最大桁数
  "lengthTwoHundredFiftySix": 256,
  //500の最大桁数
  "lengthFiveHundred": 500,
  //１つの品目名の最大桁数は100である, 最大100まで指定可能（最大桁数は100個品目名の桁数合計）
  "totalLengthGLNO": 4099,
  //１つの品目コードの最大桁数は50である, 最大100まで指定可能（最大桁数は100個品目コードの桁数合計）
  "totalLengthCode": 5396,
  //１つの品目名の最大桁数は100である, 最大100まで指定可能（最大桁数は100個品目名の桁数合計）
  "totalLengthName": 10396
}
//品目
export const classOptionsCommon = [
  {
    label: "品目",
    value: "0",
  },
  {
    label: "母材グループ",
    value: "1",
  }
];

//連関種別
export const relationClassOptionsCommon = [
  {
    label: "上位品目",
    value: "1",
  },
  {
    label: "下位品目",
    value: "2",
  }
]

//最小桁数
export const minlengthNum: { [key: string]: any } = {
  //8の最小桁数
  "lengthEight": 8,
}

//ツリーのアイコン
export const treeIcon: { [key: string]: any } = {
  //blue.png
  "Parts": "blue",
  //grey.png
  "Steel": "grey",
  //"red.png
  "Base_Material": "red",
  //"yellow.png
  "Top_Parts": "yellow",
}

//プレースホルダー
export const placeholderCommon = "こちらをクリックして検索条件を入力してください。";

export const Extract_Target: { [key: string]: any } = {
  Parts: {
    value: "00",
    text: "鋼材未到達",
  },
  Top_Parts: {
    value: "01",
    text: "鋼材・母材未到達",
  },
};

//ビジネスフラグ
export const businessFlag: { [key: string]: any } = {
  //存在しないの場合
  "zero": "0",
  //存在するの場合
  "one": "1",
}
//加工形状
export const processingShapeatusCommon = [
  {
    label: "コイル",
    value: "0",
  },
  {
    label: "シート",
    value: "1",
  },
  {
    label: "ブランク",
    value: "2",
  },
  {
    label:"台形",
    value: "3",
  },
  {
    label:"直送",
    value: "4",
  },
  {
    label: "TWB",
    value: "5",
  },
  {
    label:  "鋼管",
    value: "6",
  },
  {
    label: "棒鋼",
    value: "7",
  },
  {
    label: "線材",
    value: "8",
  },
  {
    label: "その他",
    value: "X",
  },
]
//承認済/未承認
export const approvalCompletedUncompletedCommon = [
  {
    label: "未承認のみ",
    value: "0",
  },
  {
    label: "承認済のみ",
    value: "1",
  },
  {
    label: "未承認/承認済両方",
    value: "2",
  }
]

export const sortOrder = [
  {
    label: "昇順",
    value: "0",
  },
  {
    label: "降順",
    value: "1",
  }
]
export const findConfirmStatus = (label:string):any => {
  const finalValue  ='';
  if(label !== null && label !== "" && label !== undefined){
    const obj = confirmStatus.filter((s)=>{
      if(s.label === label){
        return s;
      }
    });
    return obj.length > 0 ? obj[0].value : finalValue ;
  }
  return finalValue;
}
export const findItemClassLabel = (value:string)=>{
  const result = itemClassOptionsCommon.find((e=>e.value==value))
  return result?.label
}
export const findItemRelationClassLabel = (value:string)=>{
  const result = itemRelationClasssCommon.find((e=>e.value==value))
  return result?.label
}
export const findusumebikiOptionsCommonLabel = (value:string)=>{
  const result = usumebikiOptionsCommon.find((e=>e.value==value))
  return result?.label
}
export const findProcessingShapeatusCommonLabel = (value:string)=>{
  const result = processingShapeatusCommon.find((e=>e.value==value))
  return result?.label
}
export const halfAngleSpace = '|'

export const roleId: { [key: string]: any } = {
  // PF管理者
  "PF_KANRISHA":"PF_KANRISHA",
  // PF運用者
  "PF_UNYOSHA":"PF_UNYOSHA",

  // OEM（原単位連関表担当者）
  "OEM":"OEM",
  // OEM（原単位連関表処理代行担当者）
  "SHORIDAIKO_OEM":"SHORIDAIKO_OEM",
  // OEM（月次DX担当者）
  "OEM_MONTHLY_DX_TANTO":"OEM_MONTHLY_DX_TANTO",
  // OEM（月次DX承認者）
  "OEM_M_DX_SYOUNINN":"OEM_M_DX_SYOUNINN",
  // OEM（月次DX処理代行担当者）
  "SHORIDAIKO_OEM_M_DX":"SHORIDAIKO_OEM_M_DX",
    
  // 部品メーカー（原単位連関表担当者）
  "BUHIN_MEKA":"BUHIN_MEKA",
  // 部品メーカー（原単位連関表処理代行担当者）
  "SHORIDAIKO_BUHINMEKA":"SHORIDAIKO_BUHINMEKA",
  // 部品メーカー（月次DX担当者）
  "BUHIN_MEKA_M_TANTO":"BUHIN_MEKA_M_TANTO",
  // 部品メーカー（月次DX承認者）
  "BUHIN_MEKA_M_SYOUNIN":"BUHIN_MEKA_M_SYOUNIN",

  // 商社（原単位連関表担当者）
  "SYOSYA":"SYOSYA",
  // 商社(MO)（月次DX担当者）
  "MO_MONTHLY_DX_TANTO":"MO_MONTHLY_DX_TANTO",

  // 受注企業日次DX担当者
  "SO_DAILY_DX_TANTO": 'SO_DAILY_DX_TANTO',
  // 受注企業日次DX担当者（マスタ運用）
  "SO_DAILY_DX_MA": 'SO_DAILY_DX_MA',
  // 加工受注企業日次DX担当者
  "SP_DAILY_DX_TANTO": 'SP_DAILY_DX_TANTO',
  // 加工受注企業日次DX担当者（マスタ運用）
  "SP_DAILY_DX_MA": 'SP_DAILY_DX_MA',

  // 処理代行担当者
  "SHORIDAIKO_TANTOSHA":"SHORIDAIKO_TANTOSHA",
  // 注文企業日次DX担当者
  "PO_DAILY_DX_TANTO":"PO_DAILY_DX_TANTO",
  // 注文企業日次DX担当者（マスタ運用可
  "PO_DAILY_DX_MA":"PO_DAILY_DX_MA",
  // 注文企業日次DX業務代行担当者
  // 業務代行
  // 担当者
  "PO_DAILY_DX_DAIKO":"PO_DAILY_DX_DAIKO",
  // 注文企業2日次DX
  // 担当者
  "PO2_DAILY_DX_TANTO":"PO2_DAILY_DX_TANTO",

}

export const adjustmentRatioCommon = [
  // {
  //   label: "ブラック",
  //   value: "0",
  // },
  {
    label: "指定なし",
    value: "0",
  },
  {
    label: "指定あり",
    value: "1",
  }
]

//旬
export const seasonOptionsCommon = [
  {
    label: "01",
    value: "01",
  },
  {
    label: "02",
    value: "02",
  },
  {
    label: "03",
    value: "03",
  },
]
//内製外製区分
export const InternalOptionsCommon = [
  {
    label: "内製",
    value: "1",
  },
  {
    label: "外製",
    value: "2",
  }
]

export const companyNameOEM = "MMC"

// ヘッダ部のキー定義
export enum HeaderKey {
  X_USER_ID = 'X-User-Id',
  X_TRACE_ID = 'X-Trace-Id',
  X_LANG = 'X-Lang',
  X_AUTHORIZATION = 'X-Authorization',
}

// 日付時間定義
export enum DateFormat {
  YYYY_MM = 'YYYY/MM',
  MM_YYYY = 'MM/YYYY',
  YYYY_MM_DD = 'YYYY/MM/DD',
  DD_MM_YYYY = 'DD/MM/YYYY',
  YYYYMM = 'YYYYMM',
  YYYYMMDD = 'YYYYMMDD',
  YYYYMMDDHHmmss = "YYYYMMDDHHmmss",
  YYYYMMDD_HHmmss = "YYYYMMDD HHmmss",
}

//内製外製区分
export const InternalOptionsInsertCommon = [
  {
    label: "1:内製部品",
    value: "1",
  },
  {
    label: "2:外製/外注部品",
    value: "2",
  },
  {
    label: "3:外製/購入部品",
    value: "3",
  },
  {
    label: "4:外製/集中購入部品",
    value: "4",
  }
]
export const InternalOptionsInsertCommonNoColon = [
  {
    label: "内製部品",
    value: "1",
  },
  {
    label: "外製/外注部品",
    value: "2",
  },
  {
    label: "外製/購入部品",
    value: "3",
  },
  {
    label: "外製/集中購入部品",
    value: "4",
  }
]

export const NotificationTypeWebPush: { [key: string]: any } = {
  '0': 'PF03F03XXX_I_0001',
  '3': 'PF03F03XXX_I_0002',
  '5': 'PF03F03XXX_I_0003',
  '8': 'PF03F03XXX_I_0004'
}

export const NotificationTypeTransition: { [key: string]: any } = {
  '0': 'SUCCESS',
  '3': 'SUCCESS',
  '5': 'ERROR',
  '8': 'SUCCESS'
}
export const maxNotificationPerTrigger = 10;